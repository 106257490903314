export const animation = {
    in: {
        opacity: 1
    },
    out: {
        opacity: 0
    }
};

export const transition = {
     ease: "easeInOut",
     duration: 0.4
};