import './App.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Hero  from './components/Hero';
import Contact  from './components/Contact';
import About  from './components/About';
import Pricing from './components/Pricing';
import Services from './components/Services';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

function App() {
  return (
    <Router>
    
      <Nav/>
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route path="/" exact component={Hero} />
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/contact" component={Contact} />
        </Switch>
      </AnimatePresence>
      <Footer/>
    
    </Router>
  );
}

export default App;
