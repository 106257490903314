import React from 'react'
import { motion } from 'framer-motion';
import { animation, transition } from '../animations';

const Contact = () => {
   
   return (
   <motion.div initial='out' animate='in' exit='out' transition = {transition} variants={animation}>
    <section className="text-gray-400 bg-gray-800 body-font relative">
         <div className="container px-5 py-12 mx-auto" id="contact-us">
            <div className="flex flex-col text-center w-full mb-12">
               <h2 className="text-s text-indigo-400 tracking-widest font-medium title-font mb-2">GET IN TOUCH</h2>
               <h2 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">
                  Contact us
               </h2>
               <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                  If you would like to book your free consultation or get more information, please use the form below:
               </p>
            </div>
            <form name="contact-form" method="POST" data-netlify="true">
               <input type="hidden" name="form-name" value="contact-form"/>
               <div className="lg:w-1/2 md:w-2/3 mx-auto">
                  <div className="flex flex-wrap -m-2">
                     <div className="p-2 w-1/2">
                        <div className="relative">
                           <label htmlFor="name" className="leading-7 text-sm text-gray-400">Name *</label>
                           <input type="text" id="name" name="name"
                              className="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                     </div>
                     <div className="p-2 w-1/2">
                        <div className="relative">
                           <label htmlFor="email" className="leading-7 text-sm text-gray-400">Email *</label>
                           <input type="email" id="email" name="email"
                              className="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                     </div>
                     <div className="p-2 w-1/2">
                        <div className="relative">
                           <label htmlFor="phone" className="leading-7 text-sm text-gray-400">Phone *</label>
                           <input type="phone" id="phone" name="phone"
                              className="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                     </div>
                     <div className="p-2 w-1/2">
                        <div className="relative">
                           <label htmlFor="preferredTime" className="leading-7 text-sm text-gray-400">Preferred Lesson Time</label>
                           <input type="preferredTime" id="preferredTime" name="preferredTime"
                              className="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" />
                        </div>
                     </div>
                     <div className="p-2 w-full">
                        <div className="relative">
                           <label htmlFor="message" className="leading-7 text-sm text-gray-400">Message</label>
                           <textarea id="message" name="message"
                              className="w-full bg-gray-800 bg-opacity-40 rounded border border-gray-700 focus:border-indigo-500 focus:bg-gray-900 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                        </div>
                     </div>
                     {/* <div className="p-2 w-full" data-netlify-recaptcha="true"></div> */}
                     <div className="p-2 w-full">
                        <button
                           className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                           type="submit">
                        Submit
                        </button>
                     </div>
                     <div className="p-2 w-full pt-8 mt-8 border-t border-gray-800 text-center">
                        <i className="fas fa-envelope fa"></i> <a className="text-indigo-400"
                           href="mailto:info@lioramusicschool.com.au">info@lioramusicschool.com.au</a>
                        <p><i className="fas fa-phone-alt fa"></i> <a className="text-indigo-400" href="tel:0412 394 046">0412 394 046</a>
                        </p>
                        <br />
                     </div>
                     
                  </div>
               </div>
            </form>
           <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25225.316163621843!2d145.137504!3d-37.786184!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2fce869bad3cca80!2sLiora%20Music%20School!5e0!3m2!1sen!2sau!4v1629877839724!5m2!1sen!2sau" width="100%" height="300" style={{border:0 }} allowFullScreen="" loading="lazy"></iframe>
          </div>
      </section>
    </motion.div>
    )
}
export default Contact
