import React from 'react'
import { motion } from 'framer-motion';
import { animation, transition } from '../animations';

const Services = () => {
    return (
   <motion.div initial='out' animate='in' exit='out' transition = {transition} variants={animation}>
      <section className="text-gray-400 body-font bg-gray-800">
         <div className="container px-5 py-12 mx-auto border-b-2 border-gray-700" id="our-services">
            <div className="flex flex-col text-center w-full mb-10">
               <h2 className="text-s text-indigo-400 tracking-widest font-medium title-font mb-2">HOW WE CAN HELP YOU</h2>
               <div className="p-1 flex items-center justify-center">
                  <div className="w-full max-w-lg h-80 rounded-lg shadow-2xl overflow-hidden relative">
                     <img className="absolute inset-0 h-full w-full object-cover" src="/assets/img/piano-bg.jpg" alt="piano" />
                     <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
                     <div className="flex h-full items-center justify-center relative">
                        <h2 className="text-3xl text-white uppercase tracking-wider">Our Services</h2>
                     </div>
                  </div>
               </div>
               <p className="lg:w-2/3 mx-auto leading-relaxed text-base italic mt-4 text-indigo-300 text-lg">We are here to help
                  you reach
                  your musical goals.
                  Our
                  vocal training is available in person at our studio or online. We can also customise your learning based on
                  your individual needs.
               </p>
            </div>
            <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
               <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                  <div
                     className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-5 flex-shrink-0">
                     <i className="fas fa-microphone fa-2x"></i>
                  </div>
                  <div className="flex-grow">
                     <h2 className="text-white text-lg title-font font-medium mb-3">
                        Private Singing Lessons
                     </h2>
                     <p className="leading-relaxed text-base">
                        Private, one-on-one lessons with our experienced vocal coaches for all age groups. We can provide a
                        tailored learning plan based on your goals or needs.
                     </p>
                  </div>
               </div>
               <div className="p-4 md:w-1/3 flex flex-col text-center items-center border-l-2 border-gray-800">
                  <div
                     className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-5 flex-shrink-0">
                     <i className="fab fa-youtube fa-2x"></i>
                  </div>
                  <div className="flex-grow">
                     <h2 className="text-white text-lg title-font font-medium mb-3">
                        Audition Preparation
                     </h2>
                     <p className="leading-relaxed text-base">
                        We can get you ready for your next gig / audition. We can also provide you with music production tips.
                        Whether it be your next YouTube cover or a concert, we
                        can assist!
                     </p>
                  </div>
               </div>
               <div className="p-4 md:w-1/3 flex flex-col text-center items-center border-l-2 border-gray-800">
                  <div
                     className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-5 flex-shrink-0">
                     <i className="fas fa-laptop fa-2x"></i>
                  </div>
                  <div className="flex-grow">
                     <h2 className="text-white text-lg title-font font-medium mb-3">
                        Online classes
                     </h2>
                     <p className="leading-relaxed text-base">
                        If you would like to learn from the comfort of your own home, we also offer online lessons via platforms
                        such as Zoom, Google Meet or Microsoft Teams.
                     </p>
                  </div>
               </div>
               <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
                  <div
                     className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-5 flex-shrink-0">
                     <i className="fas fa-user-friends fa-2x"></i>
                  </div>
                  <div className="flex-grow">
                     <h2 className="text-white text-lg title-font font-medium mb-3">
                        Group classes
                     </h2>
                     <p className="leading-relaxed text-base">
                        If you prefer to learn with your friends, we also offer group lessons. We like to keep our group sizes
                        fairly small, to offer as much individual attention as possible.
                     </p>
                  </div>
               </div>
               <div className="p-4 md:w-1/3 flex flex-col text-center items-center border-l-2 border-gray-800">
                  <div
                     className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-5 flex-shrink-0">
                     <i className="fas fa-smile fa-2x"></i>
                  </div>
                  <div className="flex-grow">
                     <h2 className="text-white text-lg title-font font-medium mb-3">
                        Singing for fun
                     </h2>
                     <p className="leading-relaxed text-base">
                        Want to just have some fun whilst learning how to sing? We can offer casual lessons to help you practise
                        your singing and impress your friends!
                     </p>
                  </div>
               </div>
               <div className="p-4 md:w-1/3 flex flex-col text-center items-center border-l-2 border-gray-800">
                  <div
                     className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-5 flex-shrink-0">
                     <i className="fas fa-music fa-2x"></i>
                  </div>
                  <div className="flex-grow">
                     <h2 className="text-white text-lg title-font font-medium mb-3">
                        Instrumental lessons
                     </h2>
                     <p className="leading-relaxed text-base">
                        We currently offer piano lessons for beginners or young children.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
    </motion.div>  
    )
}

export default Services
