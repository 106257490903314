import React from 'react'
import { motion } from 'framer-motion';
import { animation, transition } from '../animations';

const Hero = () => {
    return (
    <motion.div initial='out' animate='in' exit='out' transition = {transition} variants={animation}  >
    <section className="text-gray-400 bg-gray-800 body-font">
         <div className="container mx-auto flex px-5 py-60 md:flex-row flex-col items-center border-b-2 border-gray-700">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0 mb-10">
               <img className="object-cover object-center rounded opacity-75" alt="hero" src="/assets/img/hero-image.jpg" />
            </div>
            <div
               className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
               <h3 className="text-m text-indigo-400 tracking-widest font-medium title-font mb-1 mb-5">
                  Let your talents shine brighter
               </h3>
               <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                  WELCOME TO LIORA MUSIC SCHOOL
               </h1>
               <div className="text-lg">
                  <p className="mb-8 leading-relaxed">
                     We are a music school based in Doncaster, Victoria.
                  </p>
                  <p className="mb-8 leading-relaxed">We provide exceptional vocal coaching to improve your range and technique, get
                     you ready for your next
                     concert or performance,
                     build self confidence and follow your passion! 
                  </p>
                  <p className="mb-8 leading-relaxed">We also provide beginner level piano lessons. Our studio is equipped with a
                     grand piano and we can also assist with music production.
                  </p>
                  <p className="leading-relaxed">We welcome all age groups! So whether you are a parent looking to expand your
                     child's musical skills or an aspiring musician, we are here to help you on your musical journey. 
                  </p>
               </div>
            </div>
         </div>
    </section>
    </motion.div>
    )
}

export default Hero