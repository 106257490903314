import React from 'react'


const Nav = () => {
    return (  
       
        <header className="text-gray-400 bg-gray-900 body-font">
         <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <a className="flex title-font font-medium items-center text-white mb-4 md:mb-0" href="/">
            <img className="w-20 h-20 bg-gray-800 bg-opacity-40 p-2" src="/assets/img/logo.png" alt="logo" />
            <span className="ml-3 text-xl uppercase text-indigo-300">Liora Music School</span>
            </a>
            <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center text-lg text-indigo-300">
               <a class="mr-5 hover:text-white" href="/"><i class="fa fa-home fa-fw" aria-hidden="true"></i> Home</a>
               <a className="mr-5 hover:text-white" href="/about">About</a>
               <a className="mr-5 hover:text-white" href="/services">Our Services</a>
               <a className="mr-5 hover:text-white" href="/pricing">Pricing</a>
               <a className="mr-5 hover:text-white" href="/contact">Contact</a>
            </nav>
         </div>
      </header>
       
    )
}

export default Nav
