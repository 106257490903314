import React from 'react'
import { motion } from 'framer-motion';
import { animation, transition } from '../animations';

const Pricing = () => {

    function togglePrice() {
          var x = document.querySelectorAll('.weekly');
          var y = document.querySelectorAll('.term');
          for (var i = 0; i < x.length; i++) {
            if (document.getElementById("toggle").checked === true) {
              x[i].classList.add('hidden');
              y[i].classList.remove('hidden');
            } else {
              x[i].classList.remove('hidden');
              y[i].classList.add('hidden');
            }
          }
        }

    return (
    <motion.div initial='out' animate='in' exit='out' transition = {transition} variants={animation}>  
    <section className="text-gray-400 bg-gray-800 body-font overflow-hidden">
         <div className="container px-5 py-12 mx-auto border-b-2 border-gray-700" id="pricing">
            <div className="flex flex-col text-center w-full mb-10">
               <h2 className="text-s text-indigo-400 tracking-widest font-medium title-font mb-2">FEE STRUCTURE</h2>
               <div className="p-1 flex items-center justify-center">
                  <div className="w-full max-w-lg h-80 rounded-lg shadow-2xl overflow-hidden relative">
                     <img className="absolute inset-0 h-full w-full object-cover" src="/assets/img/microphone-bg.jpg"
                        alt="microphone" />
                     <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
                     <div className="flex h-full items-center justify-center relative">
                        <h2 className="text-3xl text-white uppercase tracking-wider">Pricing</h2>
                     </div>
                  </div>
               </div>
               <p className="lg:w-2/3 mx-auto leading-relaxed text-base italic mt-4 text-indigo-300 text-lg">We offer flexible
                  payment
                  options at an affordable
                  cost. Refer to the fee structure below for both weekly and term payments.
               </p>
            </div>
            <div className="flex flex-row justify-center my-4 text-2xl tracking-tight font-medium text-white">
               <p className="mx-3">WEEKLY</p>

               <label htmlFor="toggle" className="flex items-center cursor-pointer">
                  <div className="relative">
                     <input id="toggle" type="checkbox" className="hidden" onClick={togglePrice} />
                     <div className="w-10 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                     <div className="toggle_dot absolute w-5 h-5 bg-white rounded-full shadow inset-y-0 left-0"></div>
                  </div>
               </label>
               <p className="mx-3">TERM</p>
            </div>
            <div className="flex flex-col md:flex-row md:transform md:scale-75 lg:scale-100 justify-center">
               <div id="weekly" className="p-4 xl:w-1/4 md:w-1/2 weekly">
                  <div className="p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                     <h2 className="text-sm tracking-widest text-gray-400 title-font mb-1 font-medium">PRIVATE LESSON
                     </h2>
                     <h2 className="text-5xl text-white leading-none flex items-center pb-4 mb-4 border-b border-gray-800">
                        <span>$40</span>
                        <span className="text-lg ml-1 font-normal text-gray-400">/ 30 minutes</span>
                     </h2>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Private 30 minute lesson
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Vocal, piano or guitar
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Weekly payments accepted
                     </p>
                  </div>
               </div>
               <div id="weekly" className="p-4 xl:w-1/4 md:w-1/2 weekly">
                  <div className="p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                     <h2 className="text-sm tracking-widest text-gray-400 title-font mb-1 font-medium">GROUP LESSON</h2>
                     <h2 className="text-5xl text-white leading-none flex items-center pb-4 mb-4 border-b border-gray-800">
                        <span>$30</span>
                        <span className="text-lg ml-1 font-normal text-gray-400">/ 45 minutes</span>
                     </h2>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Up to 3 students per group
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Vocal only
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Weekly payments accepted
                     </p>
                  </div>
               </div>
               <div id="term" className="p-4 xl:w-1/4 md:w-1/2 term hidden">
                  <div className="p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                     <h2 className="text-sm tracking-widest text-gray-400 title-font mb-1 font-medium">PRIVATE TERM PACK
                     </h2>
                     <h2 className="text-5xl text-white leading-none flex items-center pb-4 mb-4 border-b border-gray-800">
                        <span>$385</span>
                     </h2>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        11 x 30 minute private lessons
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Vocal, piano or guitar
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Up to 3 x re-make sessions available
                     </p>
                  </div>
               </div>
               <div id="term" className="p-4 xl:w-1/4 md:w-1/2 term hidden">
                  <div className="p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                     <h2 className="text-sm tracking-widest text-gray-400 title-font mb-1 font-medium">GROUP TERM PACK
                     </h2>
                     <h2 className="text-5xl text-white leading-none flex items-center pb-4 mb-4 border-b border-gray-800">
                        <span>$275</span>
                     </h2>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        11 x 45 minute lessons
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Up to 3 students per group
                     </p>
                     <p className="flex items-center text-gray-400 mb-2">
                        <span
                           className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-800 text-gray-500 rounded-full flex-shrink-0">
                           <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"
                              className="w-3 h-3" viewBox="0 0 24 24">
                              <path d="M20 6L9 17l-5-5"></path>
                           </svg>
                        </span>
                        Up to 3 x re-make sessions available
                     </p>
                  </div>
               </div>
            </div>
            <div className="flex flex-col text-center w-full">
               <p className="text-center mt-8"> <span className="font-bold">Term 2, 2024 dates:</span> April 15 - June 28 (11 weeks) </p>
              <p className="text-center mt-8">
                  Students can join at any time - fees will be calculated on a pro-rata basis if paying for the term.
               </p>
               <p className="text-center mt-8">
                  Contact us today to book a <b>FREE</b> trial lesson!
               </p>
               <a className="inline-flex mx-auto mt-4 text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg "
                  href="/contact">Book Now</a>
            </div>
         </div>
    </section>
    </motion.div> 
    )
}

export default Pricing
