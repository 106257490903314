import React from 'react'
import { motion } from 'framer-motion';
import { animation, transition } from '../animations';

const About = () => {
    return (
    <motion.div initial='out' animate='in' exit='out' transition = {transition} variants={animation}>    
    <section className="text-gray-400 bg-gray-800 body-font overflow-hidden">
        <div className="container px-5 py-12 mx-auto border-b-2 border-gray-700" id="about-us">
            <div className="flex flex-col text-center w-full mb-10">
                <h2 className="text-s text-indigo-400 tracking-widest font-medium title-font mb-2">WHO WE ARE</h2>
                <div className="p-1 flex items-center justify-center">
                    <div className="w-full max-w-lg h-80 rounded-lg shadow-2xl overflow-hidden relative">
                        <img className="absolute inset-0 h-full w-full object-cover" src="/assets/img/our-team-bg.png"
                            alt="musical-notes" />
                        <div className="absolute inset-0 bg-gray-900 bg-opacity-75"></div>
                        <div className="flex h-full items-center justify-center relative">
                            <h2 className="text-3xl text-white uppercase tracking-wider">Our Team</h2>
                        </div>
                    </div>
                </div>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base italic mt-4 text-indigo-300 text-lg">Our
                    experienced
                    and
                    caring
                    team is dedicated to
                    helping
                    you reach your musical goals and make your talents shine
                    brighter!
                </p>
            </div>
            <div className="flex w-full justify-center items-end">
                <div className="p-4 lg:w-3/4 md:w-1/2">
                    <div className="h-full flex flex-col items-center text-center">
                        <img className="inline object-cover w-48 h-48 mr-2 rounded-full" src="/assets/img/vocal-coach.png"
                            alt="Vocal Coach" />
                        <div className="w-full">
                            <h2 className="title-font font-medium text-lg text-white">Rashmi Loreinne</h2>
                            <h3 className="text-gray-500 mb-3">Director / Lead Vocal Coach</h3>
                            <p className="mb-4">
                                Rashmi is an experienced vocal coach who has been singing for over 20 years. She has
                                performed at
                                various events around Melbourne and overseas.
                                Rashmi also has AMEB qualifications in Piano. She is also currently training in the Estill Voice Model.
                            </p>
                            <p className="mb-4">Music has been a source of inspiration and positivity in her life
                                and
                                she is
                                passionate about
                                spreading the joy it brings.
                                Rashmi is a mum of three girls and would like to inspire them through her musical
                                journey.
                                She is also
                                a Pellowah healer and believes that practising music is a form of meditation.
                            </p>
                            <p className="mt-4">
                                Rashmi also holds a Masters degree in Physics - however music is her calling and she
                                looks
                                forward to
                                helping you through a positive and nurturing approach to teaching.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full justify-center items-end">
                <div className="p-4 lg:w-3/4 md:w-1/2">
                    <div className="h-full flex flex-col items-center text-center">
                        <img className="inline object-cover object-top w-48 h-48 mr-2 rounded-full" src="/assets/img/guitar-tutor.jpg"
                            alt="Administrative Assistant" />
                        <div className="w-full">
                            <h2 className="title-font font-medium text-lg text-white">Dimas Mahesha (Dimz)</h2>
                            <h3 className="text-gray-500 mb-3">Guitar tutor</h3>
                            <p className="mb-4">
                                Dimz, is a talented and experienced guitarist and songwriter. He is a key member of the band Into The Vein (@into_the_vein), contributing to songwriting and production.
                                With extensive teaching experience in guitar and bass, he began instructing after high school in Indonesia and continued while studying songwriting in Malaysia.
                            </p>
                            <p className="mb-4">   
                                Now based in Australia, Dimz runs Drigger Studio (@driggerstudio), providing mixing and mastering services for various clients since 2020.
                                Alongside studio work, Dimz remains committed to his teaching endeavors and is excited to be a part of the team here at Liora Music School!
                            </p>        
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full justify-center items-end">
                <div className="p-4 lg:w-3/4 md:w-1/2">
                    <div className="h-full flex flex-col items-center text-center">
                        <img className="inline object-cover object-top w-48 h-48 mr-2 rounded-full" src="/assets/img/admin-assistant.png"
                            alt="Administrative Assistant" />
                        <div className="w-full">
                            <h2 className="title-font font-medium text-lg text-white">Georgia Holding</h2>
                            <h3 className="text-gray-500 mb-3">Administrator</h3>
                            <p className="mb-4">
                                Georgia is our awesome administrator. She can support you with any queries you may have about LMS. 
                                She was instrumental in helping organise our inaugural annual concert and also hosted the event as the MC. 
                            </p>
                            <p className="mb-4">
                                Apart from her admin skills, Georgia is also a budding musician who has been attending vocal and piano lessons at Liora Music School for the past year.
                                She has participated in various musical activities at school such as intermediate vocal ensemble and was one of our star performers at our annual concert!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full justify-center items-end">
                <div className="p-4 lg:w-3/4 md:w-1/2">
                    <h2 className="text-s text-center text-indigo-400 tracking-widest font-medium title-font mb-2">OUR STUDENTS</h2>
                    <div className="p-1 flex items-center justify-center">
                        <div className="h-full flex flex-col items-center text-center">
                           <img className="object-cover object-center rounded opacity-75" alt="concert" src="/assets/img/lms-concert.jpg" />
                           <div className="w-full">            
                                <p className="mb-4 mt-4">
                                   Our students are our pride and joy. We hold annual concerts to give students an opportunity 
                                   to showcase their amazing talents and gain confidence! 
                                </p>
                                <p className="mb-4 mt-4">
                                   Check out our socials to see some of their awesome performances!
                                </p>
                            </div>
                        </div>
                     </div>
                </div>                    
            </div>
          </div>                              
    </section>
    </motion.div>
    )
}

export default About
